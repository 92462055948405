import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _955999ee = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _fe0b316c = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _bb8c47da = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _05037ecc = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _74cfe449 = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _0afa39ce = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _24f09fb8 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _bbb0463c = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _17d851ca = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _335e248c = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _30c8c312 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _05ea2be1 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _89f21d3c = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _5ebf79a6 = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _449e02ac = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _81f0cb6e = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _86608c3a = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _6ee65210 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _152bc704 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _980400ae = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _ebd30d52 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _ae1a0350 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _8e0a6b66 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _f47c39d4 = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _3f589372 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _41e530fc = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _fc19e4f2 = () => interopDefault(import('../pages/onboarding/money-purchase-annual-allowance.vue' /* webpackChunkName: "pages/onboarding/money-purchase-annual-allowance" */))
const _17d91fc8 = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _9b2283ba = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _06eacd21 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _084e7516 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _56b4b418 = () => interopDefault(import('../pages/onboarding/pension-savings.vue' /* webpackChunkName: "pages/onboarding/pension-savings" */))
const _6e6b3ff9 = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _08b7ece2 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _2ddad52a = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _8187df3e = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _54b4befc = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _01097d50 = () => interopDefault(import('../pages/onboarding/tapered-allowance.vue' /* webpackChunkName: "pages/onboarding/tapered-allowance" */))
const _6124b2b6 = () => interopDefault(import('../pages/onboarding/tax-charge-period.vue' /* webpackChunkName: "pages/onboarding/tax-charge-period" */))
const _a53d6db2 = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _211d1cb0 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _141b8057 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _7b885874 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _42fe422f = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _2a2b96f0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _955999ee,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _fe0b316c,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _bb8c47da,
    name: "help"
  }, {
    path: "/verify-email",
    component: _05037ecc,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _74cfe449,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _0afa39ce,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _24f09fb8,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _bbb0463c,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _17d851ca,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _335e248c,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _30c8c312,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _05ea2be1,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _89f21d3c,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _5ebf79a6,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _449e02ac,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _81f0cb6e,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _86608c3a,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _6ee65210,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _152bc704,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _980400ae,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _ebd30d52,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _ae1a0350,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _8e0a6b66,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/fund-selection",
    component: _f47c39d4,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _3f589372,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _41e530fc,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/money-purchase-annual-allowance",
    component: _fc19e4f2,
    name: "onboarding-money-purchase-annual-allowance"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _17d91fc8,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _9b2283ba,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _06eacd21,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _084e7516,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/pension-savings",
    component: _56b4b418,
    name: "onboarding-pension-savings"
  }, {
    path: "/onboarding/personal-info",
    component: _6e6b3ff9,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _08b7ece2,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _2ddad52a,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _8187df3e,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _54b4befc,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tapered-allowance",
    component: _01097d50,
    name: "onboarding-tapered-allowance"
  }, {
    path: "/onboarding/tax-charge-period",
    component: _6124b2b6,
    name: "onboarding-tax-charge-period"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _a53d6db2,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _211d1cb0,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _141b8057,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _7b885874,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _42fe422f,
    name: "blog-slug"
  }, {
    path: "/",
    component: _2a2b96f0,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
