import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2feaccb8 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _d0a581e2 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _ce731950 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _08147a42 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _aba84264 = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _56b7d9c4 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _01dc6086 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _61cffdbd = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _6a372d36 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _b40de2d6 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _5dbef492 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _1c9d03a6 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _7aaf123d = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _a5f2322a = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _97737d22 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _d4c645e4 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _0074c4fe = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _b77b66d6 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _1d059b4e = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _3a4da52e = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _60abbc1c = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _1f165473 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _3f4a6fd2 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _35773da4 = () => interopDefault(import('../pages/onboarding/final-salary.vue' /* webpackChunkName: "pages/onboarding/final-salary" */))
const _fc560e1e = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _11f2e3e8 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _8da2d0f2 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _37b87b37 = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _be052304 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _e58b1ab4 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _6af03531 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _450082be = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _1091c12c = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _35b4a974 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _16cfea34 = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _23ed0dc7 = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _deaeb7a8 = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _37cff475 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _49777adc = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _385f29e1 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _2f8b91b4 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _0531e84b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _2feaccb8,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _d0a581e2,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _ce731950,
    name: "help"
  }, {
    path: "/verify-email",
    component: _08147a42,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _aba84264,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _56b7d9c4,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _01dc6086,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _61cffdbd,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _6a372d36,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _b40de2d6,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _5dbef492,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _1c9d03a6,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _7aaf123d,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _a5f2322a,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _97737d22,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _d4c645e4,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _0074c4fe,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _b77b66d6,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _1d059b4e,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _3a4da52e,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _60abbc1c,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _1f165473,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _3f4a6fd2,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/final-salary",
    component: _35773da4,
    name: "onboarding-final-salary"
  }, {
    path: "/onboarding/fund-selection",
    component: _fc560e1e,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _11f2e3e8,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _8da2d0f2,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _37b87b37,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _be052304,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _e58b1ab4,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _6af03531,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/personal-info",
    component: _450082be,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _1091c12c,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _35b4a974,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _16cfea34,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _23ed0dc7,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _deaeb7a8,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _37cff475,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _49777adc,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _385f29e1,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _2f8b91b4,
    name: "blog-slug"
  }, {
    path: "/",
    component: _0531e84b,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
